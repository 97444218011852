<template>
  <!--invalid, touched-->
  <div>
    <ReviewContactsTable />
  </div>

</template>

<script>

import Vue from "vue";
import ReviewContactsTable from '@/src/components/Business/RequestContactsTable.vue';
export default {
  components:{
    ReviewContactsTable
  },
  data(){
    return {
        user:null,
    }
  },
  computed:{

  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
  },
  methods:{

  }
}
</script>
